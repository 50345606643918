.about_container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    overflow: auto;
    align-items: center;
}

.about_me {
    overflow: hidden;
    width: 20rem;
    height: 20rem;
    border-radius: 10rem;
    background: linear-gradient(var(--color-primary), transparent);
}

.about_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.about_card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about_card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about_icon {
    color: var(--color-primary);
    font-size: 1.7rem;
    margin-bottom: 1rem;
}

.about_card h5 {
    font-size: 1rem;
}

.about_card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about_content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .about_me {
        margin: 0 auto 3rem;
    }

    .about_container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about_content p {
        margin: 1rem 0;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .about_me {
        margin: 0 auto 3rem;
        align-items: center;
    }

    .about_cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about_content {
        text-align: center;
    }

    .about_content p {
        margin: 1rem 0;
    }
}