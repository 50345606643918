header {
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header_container {
    height: 100%;
    text-align: center;
    position: relative;
}

/* ================ CTA ================ */
.cta {
    justify-content: center;
    display: flex;
    margin-top: 3rem;
    gap: 1rem;
}

/* ================ HEADER SOCIALS ================ */
.header_socials {
    flex-direction: column;
    align-items: center;
    position: absolute;
    display: flex;
    bottom: 3rem;
    gap: 1rem;
    left: 0;
}

.header_socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ================ ME ================ */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    display: flex;
    overflow: hidden;
    position: absolute;
    width: 24rem;
    height: 24rem;
    left: calc(50% - 12rem);
    margin-top: 4rem;
    border-radius: 12rem;
}

/* ================ SCROLL DOWN ================ */
.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .header_socials,
    .scroll_down {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .me {
        margin-top: 2rem;
        width: 20rem;
        height: 20rem;
        border-radius: 10rem;
        left: calc(50% - 10rem);
    }
}